import {
 GeneralDataTypes, HeaderFooterDataTypes, ErrorPageData,
 TContactForm,
 TContactProblemData
} from './types';

import axiosInstance from 'services/common/instance';

export const getHeaderFooterService = async (): Promise<HeaderFooterDataTypes> => {
  const res = await axiosInstance.get('systems/header-footer');
  return res.data?.data;
};

export const getGeneralService = async (): Promise<GeneralDataTypes> => {
  const res = await axiosInstance.get('systems/general');
  return res.data?.data;
};

export const getPageErrorService = async (): Promise<APIResponse<ErrorPageData>> => {
  const response = await axiosInstance.get('systems/errors-pages');
  return response.data;
};

export const getProblemsService = async (): Promise<TContactProblemData[]> => {
  const res = await axiosInstance.get('contact-problems');
  return res.data.data;
};

export const postContactService = async (data: TContactForm): Promise<APIResponse<any>> => {
  const res = await axiosInstance.post('contacts', { ...data });
  return res.data;
};

export const Placeholder = '';
