import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button, Input,
  Loading,
  Typography
} from 'tfc-components';

import { COLORS } from 'utils/constants';

export type TFormValues = {
  code: string;
};
interface PromotionalCodeInputProps {
  id: string;
  name: string;
  isPayment?: boolean;
  disabledButton?: boolean;
  value: string;
  isLoading?: boolean;
  onChange?: () => void;
  handleApply?: () => void;
}

const PromotionalCodeInput: React.FC<PromotionalCodeInputProps> = ({
  id,
  name,
  onChange,
  value,
  isLoading,
  isPayment,
  disabledButton,
  handleApply
}: PromotionalCodeInputProps) => {
  const { t } = useTranslation();

  return (
    <div className="o-promoCodeInput">
      <div className="o-promoCodeInput_information">
        <Typography.Text extendClasses="color-jet fs-14x21" fontweight="500">
          {isPayment ? t('system.promotionalCode') : t('system.activeCode')}
        </Typography.Text>
        <div className="o-promoCodeInput_input">
          <Input
            placeholder={`${isPayment ? 'PRMXXXXXXXXX' : t('system.typeCode')}`}
            id={id}
            name={name}
            onChange={onChange}
            value={value}
          />
        </div>
      </div>
      <div className="o-promoCodeInput_button">
        {isPayment ? (
          <Button
            type="submit"
            loading={isLoading}
            loadingIndicator={<Loading.CircleDashed width={24} color={COLORS.deepCarotOrange} />}
            disabled={disabledButton}
            extendClasses={`${disabledButton ? 'o-promoCodeInput_btnDisabled2' : ''}`}
            onClick={handleApply}
          >
            <Typography.Text
              fontweight="500"
              extendClasses="color-giants-orange fs-16x20"
            >
              {t('system.apply')}
            </Typography.Text>
          </Button>
        ) : (
          <Button
            type="submit"
            loading={isLoading}
            loadingIndicator={<Loading.CircleDashed width={24} color={COLORS.deepCarotOrange} />}
            disabled={disabledButton}
            extendClasses={`${disabledButton ? 'o-promoCodeInput_btnDisabled2' : ''}`}
          >
            <Typography.Text
              fontweight="500"
              extendClasses="color-giants-orange fs-16x20"
            >
              {t('system.unlockPremium')}
            </Typography.Text>
          </Button>
        )}
      </div>
    </div>
  );
};

export default PromotionalCodeInput;
