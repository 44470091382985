/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useDidMount from './useDidMount';
import useGaTracker from './useGATracker';
import useGTMTracker from './useGTMTracker';

import i18n from 'i18n';
import { getPath, getStaticPath } from 'routes';
import { getLocalStorage, setLocalStorage } from 'services/common/storage';
import { getProfileAction } from 'store/auth';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  getHeaderFootersAction,
  getMenusAction,
  getPageErrorAsync,
  getStaticAllAction,
  getSystemAction,
  setLanguage,
} from 'store/system';
import {
  CONSTANT_ROUTES,
  ERROR_CODE_SUPPORTED,
  LOCAL_STORAGE,
} from 'utils/constants';

const useInitializeRender = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { pageError, pageData } = useAppSelector((state) => state.systems);
  const language = useAppSelector((state) => state.systems.language);
  const [loading, setLoading] = useState(true);
  useGaTracker();
  useGTMTracker();
  useDidMount(async () => {
    const accessToken = getLocalStorage(LOCAL_STORAGE.ACCESS_TOKEN);
    const { pathname } = window.location;

    await i18n.changeLanguage(pathname.split('/')[1] === 'en' ? 'en' : 'vi', () => {
      dispatch(setLanguage(pathname.split('/')[1] === 'en' ? 'en' : 'vi'));
      setLocalStorage(LOCAL_STORAGE.LANGUAGE, pathname.split('/')[1] === 'en' ? 'en' : 'vi');
    });

    dispatch(getSystemAction())
      .unwrap()
      .then((res) => {
        if (res.others.favicon) {
          const favicon = document.getElementById('favicon') as HTMLLinkElement;
          favicon.href = res.others.favicon;
        }
      })
      .catch((errors: ErrorSystemType[]) => {
        const errCode = errors[0]?.code as GlobalErrorCode;
        if (
          errors
          && errors.length > 0
          && ERROR_CODE_SUPPORTED.includes(errCode)
        ) {
          if (!pageError) {
            dispatch(getPageErrorAsync());
          }
        }
      });
    if (accessToken) {
      dispatch(getProfileAction()).unwrap();
    }
    setLoading(false);
  });

  useEffect(() => {
    dispatch(getStaticAllAction()).unwrap();
    dispatch(getHeaderFootersAction());
    dispatch(getMenusAction());
  }, [dispatch, language]);

  useEffect(() => {
    const { pathname, search } = window.location;
    const staticRoute = Object.values(CONSTANT_ROUTES).find(
      (route) => pathname.includes(route.vi) || pathname.includes(route.en)
    );

    if (
      language
      && (pathname === '/' || pathname === '/en' || pathname === '/en/')
    ) {
      navigate(getPath(language, '/') + search);
    } else if (staticRoute && language) {
      navigate(getPath(language, staticRoute[language]) + search);
    } else if (language && pageData) {
      const pageLangPath = pageData.translations[language].slug;
      if (pageLangPath) {
        navigate(`${getPath(language, `/${pageLangPath}`)}${search}`);
      } else {
        navigate('/404');
      }
    }
  }, [language, pageData]);
  return loading;
};

export default useInitializeRender;
