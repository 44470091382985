/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { Loading } from 'tfc-components';

import RenderPage from './RenderPage';

import { getPageService } from 'services/pages';
import { getProfileAction } from 'store/auth';
import { useAppDispatch } from 'store/hooks';
import { setPageData } from 'store/system';

const PageNavigate: React.FC = () => {
  const dispatch = useAppDispatch();
  const { slug } = useParams<{ slug: string }>();
  const [isLoadingProfile, setIsLoadingProfile] = useState(false);

  const {
    data,
    isLoading,
    error,
  } = useQuery(
    ['getPage', slug],
    () => getPageService(slug || ''),
    {
      onSuccess: (pageData) => {
        dispatch(setPageData(pageData));
      },
      enabled: !!slug,
    },
  );

  useEffect(() => {
    if (data?.pageData.templateCode === 'DELETE_ACCOUNT') {
      setIsLoadingProfile(true);
      dispatch(getProfileAction())
        .unwrap()
        .finally(() => {
          setIsLoadingProfile(false);
        });
    }
  }, [data]);

  if ((isLoading && slug) || isLoadingProfile) {
    return <div className="loading-fullscreen"><Loading.CircleDashed width={32} /></div>;
  }

  if (error && (error as ErrorSystemType).response.status) {
    return <>Error</>;
  }

  return <RenderPage pageData={data as any} />;
};

export default PageNavigate;
