/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Image, Typography } from 'tfc-components';

import success from 'assets/images/payment-success.png';
import failed from 'assets/images/shield-cross.png';
import Icon from 'components/atoms/Icon';
import Container from 'components/organisms/Container';
import { RESULT_CODE_MOMO, RESULT_CODE_STRIPE } from 'utils/constants';

const PaymentResult: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const resultCode = Number(searchParams.get('resultCode'));
  const stripeCode = Number(searchParams.get('stripeCode'));

  const [isMomoError, setMomoError] = useState(false);

  useEffect(() => {
    setMomoError(!!(resultCode && resultCode !== 0) || !!(stripeCode && stripeCode !== 13));
  }, [resultCode, stripeCode]);

  return (
    <div className="p-paymentResult">
      <Container fluid>
        <div className="p-paymentResult_wrapper">
          <Image extendClasses="p-paymentResult_image" imgSrc={isMomoError ? failed : success} alt="success" ratio={1} />
          {!isMomoError
            && <Icon iconName="success" size="64" />}
          <Typography.Heading
            type="h1"
            extendClasses="fs-24x36 color-smoky-black p-paymentResult_title"
            fontweight="600"
            textStyle="center"
          >
            {isMomoError ? resultCode ? RESULT_CODE_MOMO[resultCode]
              : RESULT_CODE_STRIPE[stripeCode] : `${t('system.premiumLaunch')}`}
          </Typography.Heading>
          <Typography.Text extendClasses="fs-16x24 color-dim-gray" textStyle="center">
            {t('system.evolMakeNew')}
            <br />
            {t('system.experienceTutor')}
          </Typography.Text>
          <Button
            extendClasses="btn-secondary btn-h52 p-paymentResult_button"
            onClick={() => navigate('/')}
          >
            <Typography.Text fontweight="600">{t('system.backToHome')}</Typography.Text>
          </Button>
        </div>
      </Container>
    </div>
  );
};

export default PaymentResult;
