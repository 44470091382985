import axiosInstance from 'services/common/instance';
import { ProductData, PromotionProductData } from 'services/products/types';

export const getAllProductsService = async (): Promise<ProductData[]> => {
  const res = await axiosInstance.get('products/web/list');
  return res.data.data;
};

export const getProductDetailService = async (id: string): Promise<ProductData> => {
  const res = await axiosInstance.get(`products/${id}`);
  return res.data.data.productData;
};

export const getProductPromotionService = async (id: string): Promise<PromotionProductData[]> => {
  const res = await axiosInstance.get(`promotions/product/${id}`);
  return res.data.data;
};
