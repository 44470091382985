import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Image } from 'tfc-components';

import logout from 'assets/icons/ic_logout.svg';
import trash from 'assets/icons/ic_trash.svg';
import useClickOutside from 'hooks/useClickOutside';
import { getStaticPath } from 'routes';
import { resetAuth } from 'store/auth';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { LOCAL_STORAGE } from 'utils/constants';
import mapModifiers from 'utils/functions';

interface UserMemuProps {
}

const UserMemu: React.FC<UserMemuProps> = ({ }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const profileRef = useRef<HTMLDivElement>(null);
  const profile = useAppSelector((state) => state.auth.profile);
  const language = useAppSelector((state) => state.systems.language);
  const staticAll = useAppSelector((state) => state.systems.staticAll);
  const [isDropdown, setIsDropdown] = React.useState(false);

  const deleteSlug = staticAll?.find((page) => page.pageData.code === 'DELETE_ACCOUNT')?.pageData.slug || '';

  useClickOutside(profileRef, () => {
    setIsDropdown(false);
  });

  const handleLogout = () => {
    dispatch(resetAuth());
    navigate(getStaticPath('HOME', language));
    localStorage.removeItem(LOCAL_STORAGE.ORDER_ID);
    localStorage.removeItem(LOCAL_STORAGE.PAGE_URL);
  };

  const handleNavigateDeleteAccount = () => {
    navigate(`${language === 'vi' ? '' : '/en'}/${deleteSlug}`);
  };

  const menus = [
    {
      label: 'Xoá tài khoản',
      value: 'deleteAccount',
      icon: trash,
      onClick: handleNavigateDeleteAccount
    },
    {
      label: 'Đăng xuất',
      value: 'logout',
      icon: logout,
      onClick: handleLogout
    }
  ];

  return (
    <div ref={profileRef} className="m-userMenu">
      {/* {window.innerWidth >= 991 && ( */}
      <div
        className={mapModifiers('m-userMenu_profile', profile?.productData?.userLevel?.name && 'premium')}
        onClick={() => setIsDropdown((prev) => !prev)}
      >
        <span className="m-userMenu_profile_avatar">
          {profile?.name?.split(' ')[0]?.charAt(0) || ''}
        </span>
        {profile?.productData?.userLevel?.name && (
          <div className="m-userMenu_profile_levelTag">
            {profile.productData?.userLevel?.name}
          </div>
        )}
      </div>
      {/* )} */}
      {isDropdown && (
        <ul className={mapModifiers('m-userMenu_dropdown', isDropdown ? 'show' : '')}>
          {
            menus.map((item) => (
              <li
                className={`${mapModifiers('m-userMenu_dropdown_item')}`}
                key={item.value}
                onClick={item.onClick}
              >
                <Image
                  imgSrc={item.icon}
                  alt="flag"
                  ratio={1}
                  extendClasses="m-userMenu_dropdown_icon"
                />
                <span className="m-userMenu_dropdown_item_label">{item.label}</span>
              </li>
            ))
          }
        </ul>
      )}
    </div>
  );
};

export default UserMemu;
