import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

const useSchema = () => {
  const { t } = useTranslation();

  const activationCodeSchema = yup.object().shape({
    code: yup
      .string()
      .matches(/^ACT/, t('message.invalidCodeFormat'))
      .length(12, t('message.invalidCodeFormat'))
      .matches(/^[A-Z]+$/, t('message.invalidCodeFormat'))
  });

  const paymentSchema = yup.object().shape({
    promotionCode: yup
      .string()
      .matches(/^PRM/, t('message.invalidCodeFormat'))
      .length(12, t('message.invalidCodeFormat'))
      .matches(/^[A-Z]+$/, t('message.invalidCodeFormat'))
  });

  const contactSchema = yup.object().shape({
    name: yup.string().required(t('system.inputRequired')),
    email: yup
    .string()
    .required(t('system.inputRequired'))
    .email(t('system.invalidEmail'))
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      t('system.invalidEmail')
    ),
    problemId: yup.number().required(t('system.inputRequired')),
    content: yup.string().required(t('system.inputRequired')),
  });

  return {
    activationCodeSchema,
    paymentSchema,
    contactSchema,
  };
};

export default useSchema;
