import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Image, Typography } from 'tfc-components';

import Container from '../Container';

import logoP from 'assets/images/logo.svg';
import Language from 'components/molecules/Language';
import UserMemu from 'components/molecules/UserMemu';
import i18n from 'i18n';
import { getStaticPath } from 'routes';
import { changeLanguageService } from 'services/auth';
import { ChangeLanguageParamsTypes } from 'services/auth/types';
import { setLocalStorage } from 'services/common/storage';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setLanguage } from 'store/system';
import { LOCAL_STORAGE } from 'utils/constants';
import mapModifiers from 'utils/functions';

export interface HeaderProps {
  logo?: string;
  menus: MenuItem[];
}

const Header: React.FC<HeaderProps> = ({ logo, menus }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const profile = useAppSelector((state) => state.auth.profile);
  const language = useAppSelector((state) => state.systems.language);
  const fullPath = location.pathname + location.search + location.hash;
  const afterSlash = fullPath.startsWith('/') ? fullPath.slice(1) : fullPath;

  const [isMobile, setIsMobile] = React.useState(window.innerWidth < 991);
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const { mutate: changeLanguageMutate, isLoading: isLoadingChangeLanguage } = useMutation(
    'change-languages',
    (params: ChangeLanguageParamsTypes) => changeLanguageService(params),
  );

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    setLocalStorage(LOCAL_STORAGE.LANGUAGE, lang);
    dispatch(setLanguage(lang));
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 991);
      if (window.innerWidth >= 991) {
        setIsMenuOpen(false);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  useEffect(() => {
    setIsMenuOpen(false);
  }, [location.pathname]);

  const handleChangeLanguage = () => {
    const languageData = language === 'vi' ? 'en' : 'vi';
    changeLanguage(languageData);
    if (profile) {
      changeLanguageMutate({
        language: languageData,
      });
    }
  };

  const handleLogin = () => {
    if (afterSlash) {
      localStorage.setItem(LOCAL_STORAGE.PAGE_URL, afterSlash);
    }
    navigate(getStaticPath('LOGIN', language));
  };

  return (
    <header className="o-header">
      <Container>
        <div className="o-header_wrapper">
          <Link className="o-header_logo" to="/">
            <Image imgSrc={logo ?? logoP} alt="Evol AI Tutor" ratio={139 / 48} />
          </Link>

          <div className={mapModifiers('o-header_nav', isMenuOpen && 'active')}>
            <div className="o-header_menu">
              {menus.map((menu) => (
                <Link
                  key={menu.id}
                  className="o-header_navItem"
                  to={menu.reference?.slug || menu.link || '#'}
                  target={menu.target}
                >
                  {menu.reference?.text || menu.title}
                </Link>
              ))}
            </div>
            <div className="o-header_controls">
              {/* <Button
                extendClasses="btn-primary btn-h44"
                onClick={() => navigate(getPath(language || 'vi', '/plan'))}
              >
                <Typography.Text fontweight="600">Premium plan</Typography.Text>
              </Button> */}
              {window.innerWidth < 991 ? (
                <Button
                  extendClasses="btn-outline btn-h44"
                  onClick={handleChangeLanguage}
                  disabled={isLoadingChangeLanguage}
                >
                  <Typography.Text fontweight="600">{language === 'vi' ? 'Tiếng Việt' : 'English'}</Typography.Text>
                </Button>
              ) : (
                <Language onHandleChangeLang={handleChangeLanguage} currentLang={language} />
                // <div
                //   className="o-header_controls_wrap"
                //   onClick={() => {
                //     if (!isLoadingChangeLanguage) {
                //       handleChangeLanguage();
                //     }
                //   }}
                // >
                //   <div className="o-header_controls_language">
                //     <Image
                //       imgSrc={language === 'vi' ? vietnam : usa}
                //       alt="flag"
                //       ratio={1}
                //     />
                //   </div>
                // </div>
              )}
            </div>
          </div>
          {profile ? (
            <UserMemu />
          ) : (
            <Button
              extendClasses="btn-outline btn-h44 o-header_loginBtn"
              onClick={handleLogin}
            >
              <Typography.Text fontweight="600">{t('system.login')}</Typography.Text>
            </Button>
          )}
          {isMobile && (
            <div
              className={mapModifiers('o-header_hamburger', isMenuOpen && 'active')}
              onClick={() => setIsMenuOpen((prev) => !prev)}
            >
              <div />
              <div />
              <div />
            </div>
          )}
        </div>
      </Container>
    </header>
  );
};

export default Header;
