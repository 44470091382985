/* eslint-disable react/no-unstable-nested-components */
import './App.scss';
import React, { Suspense } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Loading } from 'tfc-components';

import Icon from 'components/atoms/Icon';
import MainLayout from 'components/templates/MainLayout';
import useInitializeRender from 'hooks/useInitializeRender';
import useRoutesList from 'routes';
import { store } from 'store';
import { COLORS } from 'utils/constants';

const App: React.FC = () => {
  const loading = useInitializeRender();
  const routes = useRoutesList();

  if (loading) {
    return (
      <div className="loading-fullscreen">
        <Loading.CircleDashed width={32} color={COLORS.deepCarotOrange} />
      </div>
    );
  }
  return (
    <MainLayout>
      <Suspense
        fallback={(
          <div className="loading-fullscreen">
            <Loading.CircleDashed width={32} color={COLORS.deepCarotOrange} />
          </div>
        )}
      >
        <Routes>
          {routes.map((route, index) => (
            <Route
              key={`${route.path}-${index.toString()}`}
              path={route.path}
              element={route.element}
            />
          ))}
          <Route path="*" element={<div>404</div>} />
        </Routes>
      </Suspense>
    </MainLayout>
  );
};

const AppRoot: React.FC = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 0,
        refetchOnMount: true,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      },
    },
  });
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <HelmetProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
          <ToastContainer
            className="toast-custom"
            bodyClassName="toast-custom_body"
            icon={(props) => {
              if (props.type === 'error') {
                return <Icon iconName="warning" size="24" />;
              }
              if (props.type === 'info') {
                return <Icon iconName="info" size="24" />;
              }

              return <Icon iconName="successCircle" size="24" />;
            }}
            closeButton={() => null}
          />
        </HelmetProvider>
      </QueryClientProvider>
    </Provider>
  );
};

export default AppRoot;
