import dayjs from 'dayjs';
import React from 'react';

function mapModifiers(
  baseClassName: string,
  ...modifiers: (string | string[] | false | undefined)[]
): string {
  return modifiers
    .reduce<string[]>(
      (acc, m) => (!m ? acc : [...acc, ...(typeof m === 'string' ? [m] : m)]),
      [],
    )
    .map((m) => `-${m}`)
    .reduce<string>(
      (classNames, suffix) => `${classNames} ${baseClassName}${suffix}`,
      baseClassName,
    );
}

export default mapModifiers;

/*!
 * Scroll down to next block element
 */
export function scrollDownNextSection(ref: React.RefObject<HTMLDivElement>) {
  if (ref && ref.current) {
    window.scrollTo(
      { behavior: 'smooth', top: ref.current.offsetTop - 68 },
    ); // Minus header height
  }
}

/*!
 * getMousePosition(event) - cross browser normalizing of:
 * clientX, clientY, screenX, screenY, offsetX, offsetY, pageX, pageY
 * HTMLElement
 */
export function getMousePosition(
  evt:
    | React.MouseEvent<SVGPathElement, MouseEvent>
    | React.MouseEvent<SVGRectElement, MouseEvent>,
  item: HTMLDivElement,
) {
  let { pageX } = evt;
  let { pageY } = evt;
  if (pageX === undefined) {
    pageX = evt.clientX
      + document.body.scrollLeft
      + document.documentElement.scrollLeft;
    pageY = evt.clientY
      + document.body.scrollTop
      + document.documentElement.scrollTop;
  }

  const rect = item.getBoundingClientRect();
  const offsetX = evt.clientX - rect.left;
  const offsetY = evt.clientY - rect.top;

  return {
    client: { x: evt.clientX, y: evt.clientY }, // relative to the viewport
    screen: { x: evt.screenX, y: evt.screenY }, // relative to the physical screen
    offset: { x: offsetX, y: offsetY }, // relative to the event target
    page: { x: pageX, y: pageY }, // relative to the html document
  };
}

export function getDimensions(ele: HTMLDivElement) {
  const { height } = ele.getBoundingClientRect();
  const { offsetTop } = ele;
  const offsetBottom = offsetTop + height;

  return {
    height,
    offsetTop,
    offsetBottom,
  };
}

export function scrollStop(callback: (value: any) => void, time = 2000) {
  // Make sure a valid callback was provided
  if (!callback || typeof callback !== 'function') return;

  // Setup scrolling variable
  let isScrolling: any;

  // Listen for scroll events
  window.addEventListener(
    'scroll',
    () => {
      // Clear our timeout throughout the scroll
      window.clearTimeout(isScrolling);

      // Set a timeout to run after scrolling ends
      isScrolling = setTimeout(callback, time);
    },
    false,
  );
}
export const mappingURLToExternal = (path?: string) => (path ? new URL(path, window.location.origin).href : '');

export const dateCalculate = (date: Date, days: number) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

// dd-MMM-yyyy
export const dateFormat = (date: Date, language = 'en') => {
  const result = new Date(date);
  const year = result.getFullYear();
  const day = result.getDate();
  const month = result.toLocaleString(language, {
    month: language === 'vi' ? 'numeric' : 'short',
  });
  return `${day}-${month}-${year}`;
};

export const addAlpha = (color: string, opacity: number) => {
  const opa = Math.round(Math.min(Math.max(opacity ?? 1, 0), 1) * 255);
  return color + opa.toString(16).toUpperCase();
};

export const currencyFormat = (number = 0, currency = 'USD') => new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency,
}).format(number);

export const detectActivationCodeError = (code: string) => {
  switch (code) {
    case 'CodeAlreadyRedeemed':
      return 'redeem.codeAlreadyRedeemed';
    case 'CodeNotForAccount':
      return 'redeem.codeNotForAccount';
    case 'CodeIsRevoked':
      return 'redeem.codeIsRevoked';
    case 'CodeExpired':
      return 'redeem.codeExpired';
    case 'CodeUsageLimit':
      return 'redeem.codeUsageLimit';
    default:
      return 'redeem.invalidRedeemCode';
  }
};

export const daysUntil = (targetDateStr?: string) => {
  if (!targetDateStr) return null;
  const targetDate = dayjs(targetDateStr);
  const today = dayjs();
  return targetDate.diff(today, 'day');
};

const getOnLineStatus = () => (typeof navigator !== 'undefined' && typeof navigator.onLine === 'boolean' ? navigator.onLine : true);

export const useNavigatorOnLine = () => {
    const [status, setStatus] = React.useState(getOnLineStatus());

    const setOnline = () => setStatus(true);
    const setOffline = () => setStatus(false);

    React.useEffect(() => {
        window.addEventListener('online', setOnline);
        window.addEventListener('offline', setOffline);

        return () => {
            window.removeEventListener('online', setOnline);
            window.removeEventListener('offline', setOffline);
        };
    }, []);

    return status;
};
