import React, { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import Footer, { FooterProps } from 'components/organisms/Footer';
import Header, { HeaderProps } from 'components/organisms/Header';
import useWindowDimensions from 'hooks/useWindowDemensions';
import { useAppSelector } from 'store/hooks';
import { useNavigatorOnLine } from 'utils/functions';

interface MainLayoutProps {
  children?: React.ReactNode;
}
const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  const { height } = useWindowDimensions();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const isOnline = useNavigatorOnLine();
  const isMounted = useRef(false);
  const { t } = useTranslation();
  const content = searchParams.get('content');
  const { header, footer, menus } = useAppSelector((state) => state.systems);

  const headerData: HeaderProps = useMemo(() => {
    const logo = header?.blocks.header.logo?.data.path;
    const menuHeader = menus?.find(
      (item) => item.code === header?.blocks.header.menu?.data
    );
    return {
      logo,
      menus: menuHeader
        ? menuHeader.items.map((item, index) => ({
          id: item.id || index,
          title: item.title,
          reference: {
            text: item.title ?? '',
            slug: item.reference?.slug ?? item.link ?? '',
          },
        }))
        : [],
    };
  }, [header, menus]);

  const footerData: FooterProps = useMemo(() => {
    const logo = footer?.blocks.footer.logo?.data.path;
    const menu = menus?.find(
      (item) => item.code === footer?.blocks.footer.menu?.data
    );
    const socials = footer?.blocks.footer.socials?.data.map((item) => ({
      icon: item.icon?.data.path ?? '',
      link: item.socialLink?.data.url ?? '',
    }));
    const hyperlinks = menus?.find(
      (item) => item.code === footer?.blocks.footer.secondMenu?.data
    );
    return {
      logo,
      menus: menu
        ? menu.items.map((item, index) => ({
          id: item.id || index,
          title: item.title,
          reference: {
            text: item.title ?? '',
            slug: item.reference?.slug ?? item.link ?? '',
          },
        }))
        : [],
      socials: socials || [],
      hyperlinks: hyperlinks
        ? hyperlinks.items.map((item, index) => ({
          id: item.id || index,
          title: item.title,
          reference: {
            text: item.title ?? '',
            slug: item.reference?.slug ?? item.link ?? '',
          },
        }))
        : [],
    };
  }, [footer, menus]);

  useEffect(() => {
    window.scrollTo({ behavior: 'smooth', top: 0 });
  }, [location.pathname]);

  useEffect(() => {
    if (isMounted.current) {
      if (isOnline) {
        toast.success(t('message.online'));
      } else {
        toast.error(t('message.offline'));
      }
    } else {
      isMounted.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnline]);

  useEffect(() => {
    // Your script code here
    // Example: dynamically load a script
    const script = document.createElement('script');
    script.src = '//js.hs-scripts.com/48058777.js';
    script.async = true;
    script.id = 'hs-script-loader';
    document.body.appendChild(script);

    const hotjarScript = document.createElement('script');
    hotjarScript.innerHTML = `(function(h,o,t,j,a,r){
      h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
      h._hjSettings={hjid:5200127,hjsv:6};
      a=o.getElementsByTagName('head')[0];
      r=o.createElement('script');r.async=1;
      r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
      a.appendChild(r);
      })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;
    document.body.appendChild(hotjarScript);
  }, []);

  if (content) {
    return children as JSX.Element;
  }

  return (
    <main className="t-mainLayout" style={{ minHeight: `${height}px` }}>
      <Header {...headerData} />
      <div className="t-mainLayout_body">{children}</div>
      <Footer {...footerData} />
    </main>
  );
};

export default MainLayout;
