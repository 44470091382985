import React, { useRef, useState } from 'react';
import { Image } from 'tfc-components';

import usa from 'assets/images/usa_flag.svg';
import vietnam from 'assets/images/vietnam_flag.svg';
import IconCustom from 'components/atoms/Icon';
import useClickOutside from 'hooks/useClickOutside';
import mapModifiers from 'utils/functions';

interface LanguageProps {
  onHandleChangeLang?: () => void;
  currentLang?: string;
}

const langs = [
  {
    label: 'Tiếng Việt',
    value: 'vi',
    icon: vietnam
  },
  {
    label: 'English',
    value: 'en',
    icon: usa
  }
];

const Language: React.FC<LanguageProps> = ({ currentLang, onHandleChangeLang }) => {
  const [showLanguages, setShowLanguage] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useClickOutside(dropdownRef, () => {
    if (dropdownRef.current && showLanguages) {
      setShowLanguage(false);
    }
  });

  return (
    <div className="m-language" ref={dropdownRef}>
      <div className={mapModifiers('m-language_display', showLanguages ? 'activeDropdown' : '')} onClick={() => setShowLanguage(!showLanguages)}>
        <Image
          imgSrc={currentLang === 'vi' ? vietnam : usa}
          alt="flag"
          ratio={1}
          extendClasses="m-language_display_icon"
        />
        <span className="m-language_display_label">{currentLang === 'vi' ? 'Tiếng Việt' : 'English'}</span>
        <span className="m-language_display_arrow" />
      </div>
      <ul className={mapModifiers('m-language_dropdown', showLanguages ? 'show' : '')}>
        {
          langs.map((item) => (
            <li
              className={mapModifiers('m-language_dropdown_item', currentLang === item.value ? 'active' : '')}
              key={item.value}
              onClick={() => {
                setShowLanguage(!showLanguages);
                if (onHandleChangeLang) onHandleChangeLang();
              }}
            >
              <div className="m-language_dropdown_item-left">
                <Image
                  imgSrc={item.icon}
                  alt="flag"
                  ratio={1}
                  extendClasses="m-language_dropdown_icon"
                />
                <span>{item.label}</span>
              </div>
              <IconCustom iconName="picked" size="20" className={mapModifiers('m-language_dropdown_icChecked', currentLang === item.value ? 'active' : '')} />
            </li>
          ))
        }
      </ul>
    </div>
  );
};

export default Language;
